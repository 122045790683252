import { createStore } from 'vuex'

export default createStore({
  state: {
    sidebarVisible: '',
    sidebarUnfoldable: false,
    permissions: [],
  },
  mutations: {
    toggleSidebar(state) {
      state.sidebarVisible = !state.sidebarVisible
    },
    toggleUnfoldable(state) {
      state.sidebarUnfoldable = !state.sidebarUnfoldable
    },
    updateSidebarVisible(state, payload) {
      state.sidebarVisible = payload.value
    },
    setPermissions(state, n) {
      state.permissions = n
    },
    setUser(state, n) {
      state.user_info = n
    },
  },
  getters: {
    getPermissions: (state) => {
      return state.permissions
    },
    getUser: (state) => {
      return state.user_info
    },
  },
  actions: {},
  modules: {},
})
