import store from './store'
export const commonfunctions = {
  methods: {
    loadPrint() {
      document.getElementById('print_element').style.display = 'inline'
      document.getElementsByTagName('body')[0].style.zoom = 0.55
      window.print()
      document.getElementsByTagName('body')[0].style.zoom = 1
      document.getElementById('print_element').style.display = 'none'
    },
    loadPDF() {
      var self = this
      let postConfig = {
        responseType: 'blob',
      }
      this.formData = new FormData()
      this.formData.append('id', self.form_master_id)
      this.formData.append('branch', self.branch)
      this.axios
        .post('/form/print', this.formData, postConfig)
        .then(function (response) {
          /*
          var blob = new Blob([response.data], {type: 'application/pdf'}) //this make the magic
          var blobURL = URL.createObjectURL(blob)
          var iframe =  document.createElement('iframe') //load content in an iframe to print later
          document.body.appendChild(iframe)
          iframe.style.display = 'none';
          iframe.style.padding = 0;
          iframe.src = blobURL
          iframe.onload = function() {
            setTimeout(function() {
              iframe.focus()
              try {
                iframe.execCommand('print', false, null);
              }
              catch(e) {
                iframe.contentWindow.print()
              }
              URL.revokeObjectURL(blob)
            }, 100)
          };
          */
          const url = window.URL.createObjectURL(
            new Blob([response.data], { type: 'application/pdf' }),
          )
          const link = document.createElement('a')
          link.href = url
          link.target = '_blank'
          //link.setAttribute("download", self.branch+"_"+name+"_"+self.form_master_id+".pdf");
          document.body.appendChild(link)
          link.click()
        })
    },
    downloadExcel(dataset) {
      let postConfig = {
        responseType: 'blob',
      }
      this.formData = new FormData()
      this.formData.append('type', dataset['type'])
      this.formData.append('start_date', dataset['start_date'])
      this.formData.append('end_date', dataset['end_date'])
      this.axios
        .post('/booking/report', this.formData, postConfig)
        .then(function (res) {
          const url = window.URL.createObjectURL(new Blob([res.data]))
          const link = document.createElement('a')
          link.href = url
          link.setAttribute("download", dataset['name']+".xlsx")
          document.body.appendChild(link)
          link.click()
        })
    },
    generateReport() {
      this.$refs.html2Pdf.generatePdf()
    },
    has_permission(permission) {
      if (store.getters.getPermissions.includes(permission)) {
        return true
      } else {
        return false
      }
    },
    get_user_info() {
      return store.getters.getUser
    },
    reload() {
      location.reload()
    },
    complete() {
      var self = this
      if (self.close_tab == true) {
        window.close()
        setTimeout(function () {
          self.$router.push("/eForm/form_application/"+self.branch)
          setTimeout(function () {
            self.reload()
          }, 700)
        }, 400)
      } else {
        self.reload()
      }
    },
  },
}
