<template>
  <div>
    <!-- bg-success bg-opacity-1 -->
    <div class="wrapper d-flex flex-column min-vh-100 bg-white">
      <div class="body flex-grow-1 px-3">
        <div class="body flex-grow-1 px-3 text-center">
          <router-view />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'BlankLayout',
  components: {},
}
</script>
